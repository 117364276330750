import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { LoginView } from './domain/Login/Views/LoginView'
import { ResetPasswordView } from './domain/Login/Views/ResetPasswordView'
import { GlobalErrorView } from './domain/Errors/Views/GlobalErrorView'
import { config } from './config'
import { routes } from './routes'
import { BuildingsView } from './domain/HierarchyView/Views/BuildingsView/BuildingsView'
import { Logo } from './domain/Core/components/Logo/Logo'
import { BuildingOverviewView } from './domain/HierarchyView/Views/BuildingOverviewView/BuildingOverviewView'
import { RedirectorTemplate } from './domain/Generic/components/RedirectorTemplate'
import { withLogin } from './domain/Auth/services/service'
import { TelemetryProvider } from './domain/Telemetry/components/TelemetryProvider'
import useScroll from './Core/utils/useScroll'
import useResize from './Core/utils/useResize'
import { Shield } from './domain/Errors/Shield'

withLogin()

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={() => {
                const path = config.defaultPath

                return <Redirect to={path} />
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView logo={<Logo />} />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route
                        exact={true}
                        path={routes.login}
                        render={props => (
                            <LoginView
                                {...(props as RouteComponentProps<
                                    Record<string, never>,
                                    Record<string, unknown>,
                                    { force: boolean }
                                >)}
                                logo={<Logo />}
                            />
                        )}
                    />
                    <Route
                        exact={true}
                        path={routes.resetPassword}
                        render={props => <ResetPasswordView {...props} logo={<Logo />} />}
                    />
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.buildings} component={BuildingsView} />
                    <Route exact={true} path={routes.buildingOverview()} component={BuildingOverviewView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
