const regexpEnvPlaceholder = /^{{[A-Z_0-9]+}}$/

export function getEnv(name: string, defaultValue: string = ''): string {
    if (window.__ENV && window.__ENV[name] && !window.__ENV[name].match(regexpEnvPlaceholder)) {
        return window.__ENV[name]
    }
    return process.env[`REACT_APP_${name}`] || defaultValue
}
export interface OAuthConfig {
    basePath: string
    token: string
    user: string
    revocation: string
    clientSecret: string
    clientId: string

    resetPassword: string
    updatePassword: string

    apiKey: string

    persistence: string
}

export interface Config {
    defaultLocale: string

    hiearchy: {
        buildings: string
        floors: (buildingRef: string) => string
        rooms: (buildingRef: string, floorRef: string) => string
        cleaned: (buildingRef: string, floorRef: string, roomRef: string) => string
        revert: (buildingRef: string, floorRef: string, roomRef: string) => string
    }

    preferences: {
        preferencesCombined: (userId: string) => string
    }

    defaultPath: string
    basePath: string
    instrumentationKey: string

    oauth: OAuthConfig

    ad: ADConfig
}

export interface ADConfig {
    url: string
    redirectPath: string
}

export const config: Config = {
    defaultPath: getEnv('DEFAULT_PATH', '/'),
    basePath: getEnv('BASE_PATH'),
    instrumentationKey: getEnv('APPINSIGHTS_INSTRUMENTATIONKEY'),

    oauth: {
        basePath: getEnv('OAUTH_BASE_PATH', 'https://iot-unica-canaries-identityservice.azurewebsites.net/'),
        clientSecret: getEnv('OAUTH_CLIENT_SECRET'),
        clientId: getEnv('OAUTH_CLIENT_ID'),
        apiKey: getEnv('OAUTH_API_KEY'),
        token: 'connect/token',
        user: 'connect/userinfo',
        revocation: 'connect/revocation',
        resetPassword: 'api/passwords/create-reset-token',
        updatePassword: 'api/passwords/update',
        persistence: 'api/persistence',
    },

    ad: {
        url: getEnv('AD_URL'),
        redirectPath: getEnv('AD_REDIRECT_PATH', '/ad/redirect'),
    },

    defaultLocale: 'nl',

    hiearchy: {
        buildings: 'api/views/hierarchy/buildings',
        floors: (buildingRef: string) => `api/views/hierarchy/buildings/${buildingRef}/floors`,
        rooms: (buildingRef: string, floorRef: string) =>
            `api/views/hierarchy/buildings/${buildingRef}/floors/${floorRef}/rooms`,
        cleaned: (buildingRef: string, floorRef: string, roomRef: string) =>
            `api/views/hierarchy/buildings/${buildingRef}/floors/${floorRef}/rooms/${roomRef}/cleaned`,
        revert: (buildingRef: string, floorRef: string, roomRef: string) =>
            `api/views/hierarchy/buildings/${buildingRef}/floors/${floorRef}/rooms/${roomRef}/revert`,
    },

    preferences: {
        preferencesCombined: (userId: string) => `p/preferences/fetch/${userId}`,
    },
}
