import styles from './Birds.module.scss'

import React from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
}

export const Birds: React.FunctionComponent<Props> = props => {
    return (
        <svg
            className={classNames(styles.container, props.className)}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 23"
        >
            <g fill="none" fillRule="evenodd">
                <g fill="#CBD4D7">
                    <path
                        d="M285 34.883c3.052-.852 5.207-1.087 6.466-.704 1.26.382 2.676 1.702 4.25 3.959 2.169-1.518 4.123-2.317 5.861-2.397 1.739-.081 4.546.497 8.423 1.734-3.75-.323-6.558-.323-8.423 0-1.865.323-4.05 1.187-6.553 2.592-2.012-2.215-3.665-3.657-4.96-4.326-1.294-.67-2.982-.956-5.064-.858zm22.454-15.58c4.123-.907 6.988-1.006 8.594-.296 1.606.71 3.298 2.823 5.076 6.34 3.048-1.993 5.72-2.949 8.017-2.868 2.297.08 5.914 1.258 10.852 3.532-4.89-.909-8.582-1.232-11.076-.97-2.494.263-5.478 1.29-8.953 3.078-2.359-3.505-4.346-5.826-5.961-6.965-1.616-1.138-3.799-1.755-6.55-1.85z"
                        transform="translate(-285 -18)"
                    />
                </g>
            </g>
        </svg>
    )
}
