import { client } from '../../../Client/services/client'
import { ResponsePromise } from '../../../Client/services/request'
import { config } from '../../../config'
import { BuildingView, CleanState, FloorView, RoomView } from './types'

export interface IHierarchyService {
    getBuildings: () => ResponsePromise<BuildingView[]>
    getFloors: (buildingRef: string) => ResponsePromise<FloorView[]>
    getRooms: (buildingRef: string, floorRef: string) => ResponsePromise<RoomView[]>
    postCleaned: (buildingRef: string, floorRef: string, roomRef: string) => ResponsePromise<CleanState>
    postRevert: (buildingRef: string, floorRef: string, roomRef: string) => ResponsePromise<CleanState>
}

export const hierarchyService: IHierarchyService = {
    getBuildings() {
        return client.get<BuildingView[]>(config.hiearchy.buildings)
    },
    getFloors(buildingRef: string) {
        return client.get<FloorView[]>(config.hiearchy.floors(buildingRef))
    },
    getRooms(buildingRef: string, floorRef: string) {
        return client.get<RoomView[]>(config.hiearchy.rooms(buildingRef, floorRef))
    },
    postCleaned(buildingRef: string, floorRef: string, roomRef: string) {
        return client.post(config.hiearchy.cleaned(buildingRef, floorRef, roomRef))
    },
    postRevert(buildingRef: string, floorRef: string, roomRef: string) {
        return client.post(config.hiearchy.revert(buildingRef, floorRef, roomRef))
    },
}
