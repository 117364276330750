export interface Geo {
    city: string
    address: string
}

export interface BuildingView {
    id: string
    name: string
    imgRef?: string
    geo: Geo
}

export enum CleanedStateValue {
    NotUsed = 'NotUsed',
    NeedToBeChecked = 'NeedToBeChecked',
    NeedToBeCleaned = 'NeedToBeCleaned',
    Cleaned = 'Cleaned',
}

export enum RoomType {
    Office = 'office',
    Meetting = 'meeting',
}

export interface FloorView {
    id: string
    name: string
    buildingRef: string
    counts: {
        -readonly [key in keyof typeof CleanedStateValue]: number
    }
}

export interface CleanState {
    value: CleanedStateValue
    time: Date
}

export interface RoomView {
    id: string
    name: string
    buildingRef: string
    roomRef: string
    roomType: RoomType | string
    state: CleanState
}

export interface CustomerLogo {
    logo?: string
}
