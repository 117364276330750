import React from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
}

export const BuildingBackground: React.FunctionComponent<Props> = props => {
    return (
        <svg className={classNames(props.className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 528">
            <defs>
                <linearGradient id="htn49lybpa" x1="49.493%" x2="38.183%" y1="95.777%" y2="1.632%">
                    <stop offset="0%" stopColor="#86C1B3" />
                    <stop offset="36.843%" stopColor="#FCD9D3" />
                    <stop offset="65.709%" stopColor="#E8E2F3" />
                    <stop offset="100%" stopColor="#88BFE0" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g>
                    <path fill="url(#htn49lybpa)" fillOpacity=".8" d="M0 0H360V528H0z" />
                    <g>
                        <path
                            fill="#FFF"
                            fillOpacity=".5"
                            d="M8.385 78.866c81.346 2.34 183.89 2.34 323.997 0 6.496-.108 8.834-6.154 4.172-12.611-4.662-6.457-27.93-21.426-64.502-11.557-31.323 8.461-69.409-7.06-86.697-7.633-41.902-1.39-53.19 19.867-78.335 19.867-25.145 0-27.341-20.299-63.997-17.709C26.045 50.423 7.218 59.468 1.4 68.26-1.68 72.911.18 78.631 8.385 78.866z"
                            transform="translate(11 348)"
                        />
                        <path
                            fill="#A0ACBB"
                            d="M308.883 80.588l2.525.088-.126-8.96s2.374-2.4 3.956-2.24c1.582.162 5.22.96 7.28-1.12 2.059-2.078.474-4.318 1.582-4.318 1.107 0-.949-2.558-.949-3.678 0-.722 2.116-12.14-2.518-11.017-4.071.974-5.92-6.561-10.363-3.684-3.51 2.273-8.228.938-8.553 3.168-.783 5.35-5.307 3.657-4.653 6.732.95 4.48.3 6.4 2.195 6.88 1.894.48-.474 2.558.315 4.16.79 1.603.16 3.2 2.375 3.2s3.638.64 5.064.959c1.426.318 1.74 1.12 1.74 1.12l.13 8.71z"
                            transform="translate(11 348)"
                        />
                        <path
                            fill="#848E9B"
                            d="M303.885 79.722l-3.13.085V40.383s-8.266 1.35-9.186-.449c-.92-1.8-.765-2.397-3.52-2.397-2.756 0-3.827-.15-2.45-2.55 1.378-2.4 3.37.304 1.378-2.397-1.99-2.701-2.864-4.726-.109-6.374 2.756-1.648 1.733-1.126 1.733-4.273s3.733.304 2.968-2.843c-.765-3.148-3.469-2.79-3.314-5.937.155-3.147 2.243-4.707 2.091-5.605-.152-.899 2.143-2.55 3.827-4.197 1.685-1.648 1.991-3.749 5.205-3.3 3.214.45 4.288.608 3.369 3.3-.92 2.691 3.217.607 3.827 1.499.61.892-.458 1.8 1.378 2.397 1.836.598 1.214.45-.765 1.648-1.979 1.2-.607 2.25.765 2.25 1.371 0 2.91-2.699 3.217-1.5.306 1.199 2.142.607.91 3.897-1.232 3.29-1.214 2.698-.607 3.299.607.6.765.607 0 1.35-.765.744 3.52-.607 3.52 1.05s.304 4.798-.764 7.044c-1.068 2.246 4.288.45 4.133 1.5-.154 1.05-.303 2.097-1.377 2.397-1.075.3-3.982.304-2.143 1.05 1.839.747 1.72 2.34 1.72 2.941 0 .6 1.251 2.659-.74 3.26-1.99.6 2.389 2.795.553 2.943-2.223.18-4.458-.074-6.583-.75-2.143-.749-4.902-.91-5.36.304-.458 1.214-1.378.304-1.378.304l.832 39.478z"
                            transform="translate(11 348)"
                        />
                        <path
                            fill="#A0ACBB"
                            d="M23.127 80.277l-.279-8.877s-10.886-.507-6.23-9.014c0 0 1.122-.16 1.122-1.605s-2.567-1.445-2.73-2.407c-.165-.962-.162-3.035 2.406-4.813 2.567-1.779-3.211-5.766-3.369-6.419-.158-.652 0-2.246 1.766-3.851 1.767-1.606 2.246-3.369 2.085-4.974-.16-1.606-4.494-3.69-2.567-5.296 1.927-1.606 5.94-2.89 6.258-4.173.318-1.284-.161-7.062 3.69-5.618 3.851 1.445 12.358-.322 14.125-.16 2.47.165 4.723 1.47 6.097 3.529 1.927 2.731 3.21.801 4.012 2.731.801 1.93-.962 8.195.482 9.79 1.445 1.597 3.69 2.89 2.732 5.767-.96 2.877-4.495 4.656-4.495 5.766 0 1.111 1.606 4.975 1.606 6.098 0 1.123-.644 2.889-2.568 3.21-1.924.322-2.085 1.284-2.085 3.37 0 2.084-1.445.482-1.445.482l.322 15.888h-2.406s0-14.604-.483-16.21h-1.606l.161 16.21h-1.927l-.16-14.444s-2.407-2.889-3.37-3.034c-.961-.146.644 17.814.644 17.814h-1.766l-.483-16.37s-.801 3.211-1.605 3.211c-.805 0 .321 12.999.321 12.999h-1.766l-.643-13.16s-.962-1.766-1.767.483c-.804 2.249-1.378.44-1.214 1.402.164.962.21 11.672.21 11.672l-3.075.003z"
                            transform="translate(11 348)"
                        />
                    </g>
                    <path fill="#FFF" fillOpacity=".5" d="M67 144H293V439H67z" />
                    <g>
                        <path
                            fill="#FFF"
                            fillOpacity=".5"
                            d="M28.272 66.476L254.272 5.476 254.272 74.476 28.272 74.476z"
                            transform="translate(38.728 69.524)"
                        />
                        <path
                            fill="#848E9B"
                            fillRule="nonzero"
                            d="M17.272 66.519L265.272 0 265.272 5.403 17.272 71.881z"
                            transform="translate(38.728 69.524)"
                        />
                        <path
                            fill="#FFF"
                            fillOpacity=".5"
                            d="M0 26.476c3.052-.852 5.207-1.087 6.466-.704 1.26.382 2.676 1.702 4.25 3.959 2.169-1.517 4.123-2.316 5.861-2.397 1.739-.08 4.546.497 8.423 1.734-3.75-.323-6.558-.323-8.423 0-1.865.323-4.05 1.187-6.553 2.592-2.012-2.215-3.665-3.657-4.96-4.326-1.294-.67-2.982-.955-5.064-.858zm24.454-20.58c4.123-.907 6.988-1.006 8.594-.296 1.606.71 3.298 2.823 5.076 6.34 3.048-1.993 5.72-2.949 8.017-2.868 2.297.08 5.914 1.258 10.852 3.532-4.89-.908-8.582-1.231-11.076-.969-2.494.263-5.478 1.288-8.953 3.077-2.359-3.505-4.346-5.826-5.961-6.964-1.616-1.139-3.799-1.756-6.55-1.851z"
                            transform="translate(38.728 69.524)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
