import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ResetPassword } from '../components/ResetPassword/ResetPassword'
import { parse } from 'query-string'
import { PageError } from '../../../Core/components/PageError/PageError'

export interface Params extends RouteComponentProps {
    logo: JSX.Element
}

export const ResetPasswordView: FunctionComponent<Params> = params => {
    const tokenParams = parse(params.location.search)?.token

    const token = Array.isArray(tokenParams) ? tokenParams[0] : tokenParams

    if (!token) {
        throw new PageError('Tocken is null')
    }

    return <ResetPassword token={token} logo={params.logo} />
}
