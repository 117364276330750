import { BuildingView, FloorView, RoomView } from './types'
import { hierarchyService } from './service'
import { RequestStatus, useRequest } from '../../../Client/services/request'

export const useGetBuildings = (): RequestStatus<BuildingView[] | undefined> => {
    return useRequest(hierarchyService.getBuildings, [])
}

export const useGetFloors = (buildingRef?: string, state?: number): RequestStatus<FloorView[] | undefined> => {
    const getProsmise = () => (buildingRef ? hierarchyService.getFloors(buildingRef) : undefined)

    return useRequest(getProsmise, [buildingRef, state])
}

export const useGetRooms = (buildingRef?: string, floorRef?: string): RequestStatus<RoomView[] | undefined> => {
    const getProsmise = () => (buildingRef && floorRef ? hierarchyService.getRooms(buildingRef, floorRef) : undefined)

    return useRequest(getProsmise, [buildingRef, floorRef])
}
