import React, { Fragment, useContext, useState } from 'react'
import classNames, { Argument } from 'classnames'
import { Icon, Icons } from '../../../Core/components/Icon/Icon'
import { InfoText } from '../../../Core/components/Typography/Text/Text'
import styles from './RoomCard.module.scss'
import { CleanedStateValue, CleanState, RoomType, RoomView } from '../../services/types'
import { hierarchyService } from '../../services/service'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../../Core/components/ActivityIndicator/ActicvityIndicator'

interface Props {
    className?: Argument
    room: RoomView
    onUpdate?: (state: CleanState) => void
}

export const RoomCard: React.FunctionComponent<Props> = ({ className, room, onUpdate }) => {
    const local = useContext(LocaleContext)

    const [loading, setLoading] = useState(false)

    const onClick = async () => {
        setLoading(true)

        try {
            let result

            if (room.state.value === CleanedStateValue.Cleaned) {
                result = await hierarchyService.postRevert(room.buildingRef, room.roomRef, room.id)
            } else {
                result = await hierarchyService.postCleaned(room.buildingRef, room.roomRef, room.id)
            }

            if (result.status >= 200 && result.status < 300) {
                onUpdate && onUpdate(result.data)
            }
        } catch {}

        setLoading(false)
    }

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.state1]: room.state?.value === CleanedStateValue.NeedToBeCleaned,
                [styles.state2]: room.state?.value === CleanedStateValue.NeedToBeChecked,
            })}
            onClick={onClick}
        >
            {loading ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.small} className={styles.loading} />
            ) : (
                <Fragment>
                    {(room.roomType as RoomType) && <Icon icon={Icons[room.roomType as RoomType]} />}
                    <InfoText className={styles.name}>
                        {room.roomType && `${local._(`rooms.${room.roomType}`)}`} {room.name}
                    </InfoText>
                    {room.state?.value === CleanedStateValue.NeedToBeCleaned && <Icon icon={Icons.broom} />}
                    {room.state?.value === CleanedStateValue.NeedToBeChecked && <Icon icon={Icons.question} />}
                    {(room.state?.value === CleanedStateValue.NotUsed ||
                        room.state?.value === CleanedStateValue.Cleaned) && <Icon icon={Icons.check} />}
                </Fragment>
            )}
        </div>
    )
}
