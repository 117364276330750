import styles from './ErrorLayout.module.scss'
import React, { useContext } from 'react'
import classNames, { Argument } from 'classnames'
import Title, { FontSize, HeadingLevel } from '../../Typography/Title'
import { Footer } from '../Footer/Footer'
import { LocaleContext } from '../../../../Providers/LocaleProvider'

interface Props {
    className?: Argument
    renderButton?: () => JSX.Element
    showFooter?: boolean
    logo: JSX.Element
}

export const ErrorLayout: React.FunctionComponent<Props> = props => {
    const locale = useContext(LocaleContext)

    return (
        <div
            className={classNames(styles.container, props.className, {
                [styles.showFooter]: !!props.showFooter,
            })}
        >
            <div className={styles.content}>
                {props.logo}
                <Title className={styles.title} headingLevel={HeadingLevel.h1} fontSize={FontSize.size44}>
                    {locale._('ErrorLayout.title')}
                </Title>
                {props.renderButton && <div className={styles.buttonWrapper}>{props.renderButton()}</div>}
            </div>
            {props.showFooter && <Footer />}

            <svg
                className={styles.bottomCloud}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 595 204"
                enableBackground="new 0 0 595 204"
            >
                <g transform="translate(.592)">
                    <defs>
                        <filter id="a" filterUnits="userSpaceOnUse" x="1.1" y=".3" width="253.8" height="173.3">
                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                        </filter>
                    </defs>
                    <mask maskUnits="userSpaceOnUse" x="1.1" y=".3" width="253.8" height="173.3" id="b">
                        <g filter="url(#a)">
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFF" d="M.4 0h593v204H.4z" />
                        </g>
                    </mask>
                    <linearGradient
                        id="c"
                        gradientUnits="userSpaceOnUse"
                        x1="-7.058"
                        y1="498.216"
                        x2="-7.058"
                        y2="497.232"
                        gradientTransform="matrix(141.5962 174.8568 176.5857 -142.9963 -86764.672 72498.195)"
                    >
                        <stop offset="0" stopColor="#ffd291" />
                        <stop offset="1" stopColor="#9dc6f9" />
                    </linearGradient>
                    <path
                        mask="url(#b)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="url(#c)"
                        d="M239.6 53c24.9 30.7 18.7 76.8-13.7 103.1-29.1 23.6-69.5 23.1-95.4.5-37.1 23.3-85.3 17.4-111.9-15.4C-10.2 105.6-2.8 51.7 35.2 21s92.2-26.8 121 8.8c.7.8 1.3 1.6 1.9 2.4C187 21.4 220 28.8 239.6 53z"
                    />
                </g>
                <g transform="translate(.592)">
                    <defs>
                        <filter id="d" filterUnits="userSpaceOnUse" x="19.6" y="66" width="574.7" height="326.2">
                            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
                        </filter>
                    </defs>
                    <mask maskUnits="userSpaceOnUse" x="19.6" y="66" width="574.7" height="326.2" id="e">
                        <g filter="url(#d)">
                            <path fillRule="evenodd" clipRule="evenodd" fill="#FFF" d="M.4 0h593v204H.4z" />
                        </g>
                    </mask>
                    <linearGradient
                        id="f"
                        gradientUnits="userSpaceOnUse"
                        x1="-8.271"
                        y1="498.267"
                        x2="-8.271"
                        y2="497.321"
                        gradientTransform="matrix(-522.5831 -169.7975 124.6952 -383.7725 -66084.586 189871.344)"
                    >
                        <stop offset="0" stopColor="#2a4364" />
                        <stop offset="1" stopColor="#ffd291" />
                    </linearGradient>
                    <path
                        mask="url(#e)"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="url(#f)"
                        d="M489.9 73.2c-58.5-19-121 1.1-161.6 46.4-9-6.7-19.2-12-30.3-15.6-46-14.9-95.2 3.3-124.2 41.7-1.6-.6-3.3-1.2-5-1.8C110 124.8 45.9 159.8 25.7 222s11.1 128.2 69.9 147.3c45.7 14.9 94.6-3 123.6-40.9 1.7.7 3.4 1.3 5.2 1.8 28.8 9.4 58.9 5.7 84.5-7.8 18.1 28.9 45.5 51.5 79.6 62.6 80.9 26.3 169.2-22.2 197.1-108.4S570.8 99.5 489.9 73.2z"
                    />
                </g>
            </svg>
            <svg className={styles.topCloud} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.4 155">
                <linearGradient id="z" x1="127.716" x2="127.716" y1="0" y2="154.77" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#dfceb3" />
                    <stop offset="1" stopColor="#9dc6f9" />
                </linearGradient>
                <path
                    fill="url(#z)"
                    fillRule="evenodd"
                    d="M255.4 67.4V0H0c8.1 46.8 46.6 82.3 92.9 82.3h.6c6.4 41.1 40 72.4 80.4 72.4 45 0 81.5-38.9 81.5-86.8v-.5z"
                    clipRule="evenodd"
                />
            </svg>
        </div>
    )
}
