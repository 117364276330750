import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import styles from './Login.module.scss'
import { Button } from '../../../Core/components/Button/Button'
import { useHistory } from 'react-router-dom'
import { UnicaLogo } from '../../../Core/components/Logo/UnicaLogo'
import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { Input } from '../../../Core/components/Input/Input'
import { InfoText, TextSizeEnumType, TextStyleEnumType } from '../../../Core/components/Typography/Text/Text'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import { authService } from '../../../Auth/services/service'
import { routes } from '../../../../routes'

export interface Props {
    logo: JSX.Element
}

export const Login: FunctionComponent<Props> = ({ logo }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isResetPassword, setIsResetPassword] = useState(false)
    const [isEmailSend, setIsEmailSend] = useState(false)

    const history = useHistory()

    const locale = useContext(LocaleContext)

    const handleOnLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            setLoading(true)
            setErrorMessage('')

            const authResult = await authService.postLogin(email, password)

            setLoading(false)

            if (authResult.status === 200) {
                if (history.action !== 'POP') {
                    history.goBack()
                } else {
                    history.push(routes.index)
                }
            } else {
                setErrorMessage(authResult.statusText)
            }
        } catch (e: any) {
            setLoading(false)

            let message = undefined
            switch (e.response?.status) {
                case 401:
                    message = 'Invalid username and password.'
                    break
                case 400:
                    message = e.response?.data?.error_description || e.message
                    break
                default:
                    message = e.message
            }
            setErrorMessage(message)
        }
    }

    const handleOnReset = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()

            setLoading(true)
            setErrorMessage('')

            const result = await authService.postResetPassword(email)

            if (result.status === 200) {
                setIsEmailSend(true)
            } else {
                setErrorMessage(result.statusText)
            }
        } catch (e: any) {
            setErrorMessage(e.message)
        } finally {
            setLoading(false)
        }
    }

    const setIsResetPasswordAndCleanUp = (isResetPassword: boolean) => {
        setIsResetPassword(isResetPassword)
        setEmail('')
        setPassword('')
        setErrorMessage('')
        setIsEmailSend(false)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.formWrapper}>
                {logo}
                <form className={styles.form} onSubmit={isResetPassword ? handleOnReset : handleOnLogin}>
                    {!isEmailSend && (
                        <Input
                            required={true}
                            type="email"
                            placeholder={locale._('login.emailPlaceholder')}
                            onChange={v => setEmail(v)}
                            value={email}
                        />
                    )}
                    {!isResetPassword && (
                        <Input
                            type="password"
                            placeholder={locale._('login.passwordPlaceholder')}
                            onChange={v => setPassword(v)}
                            value={password}
                            required={true}
                        />
                    )}
                    {!isEmailSend && (
                        <Button isSubmit={true} className={styles.submitButton} loading={loading}>
                            {isResetPassword ? locale._('login.reset') : locale._('login.signIn')}
                        </Button>
                    )}
                    {!isResetPassword && (
                        <TextButton onClick={() => setIsResetPasswordAndCleanUp(true)}>
                            {locale._('login.forget')}
                        </TextButton>
                    )}
                    {isEmailSend && (
                        <Fragment>
                            <InfoText className={styles.title}>{locale._('login.emailSendTitle')}</InfoText>
                            <InfoText className={styles.title}>{locale._('login.emailSendSubtitle')}</InfoText>
                        </Fragment>
                    )}
                    {isResetPassword && (
                        <TextButton onClick={() => setIsResetPasswordAndCleanUp(false)}>
                            {locale._('login.back')}
                        </TextButton>
                    )}
                    <InfoText
                        style={TextStyleEnumType.italic}
                        className={styles.errorMessage}
                        size={TextSizeEnumType.small}
                    >
                        {errorMessage}
                    </InfoText>
                </form>
                <UnicaLogo />
            </div>
        </div>
    )
}
