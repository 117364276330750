import { defineMessage } from '@lingui/macro'

export const translations = {
    login: {
        reset: defineMessage({ id: 'login.reset', message: 'Resetten' }),
        signIn: defineMessage({ id: 'login.signIn', message: 'Inloggen' }),
        change: defineMessage({ id: 'login.change', message: 'Wijzig' }),
        emailPlaceholder: defineMessage({ id: 'login.emailPlaceholder', message: 'E-mail' }),
        passwordPlaceholder: defineMessage({ id: 'login.passwordPlaceholder', message: 'Wachtwoord' }),
        confirmPasswordPlaceholder: defineMessage({
            id: 'login.confirmPasswordPlaceholder',
            message: 'Bevestig wachtwoord',
        }),
        forget: defineMessage({ id: 'login.forget', message: 'Wachtwoord vergeten?' }),
        back: defineMessage({ id: 'login.back', message: 'Terug' }),
        emailSendTitle: defineMessage({
            id: 'login.emailSendTitle',
            message: 'We hebben zojuist een e-mail verstuurd met een link om uw wachtwoord te wijzigen.',
        }),
        emailSendSubtitle: defineMessage({
            id: 'login.emailSendSubtitle',
            message:
                'Het kan een paar minuten duren voordat u deze mail ontvangt. Controleer uw spamfolder als er geen e-mail van ons in uw inbox verschijnt.',
        }),
    },
    errorView: {
        backToDefault: defineMessage({ id: 'errorView.backToDefault', message: 'Ga terug naar de standaard pagina' }),
    },
    errorLayout: {
        title: defineMessage({ id: 'ErrorLayout.title', message: 'Oeps, er is iets fout gegaan.' }),
    },
    profile: {
        logout: defineMessage({ id: 'profile.logout', message: 'Uitloggen' }),
    },
    floors: {
        groundfloor: defineMessage({ id: 'floors.groundfloor', message: 'Begane grond' }),
        '1stfloor': defineMessage({ id: 'floors.1stfloor', message: '1ste verdieping' }),
        '2ndfloor': defineMessage({ id: 'floors.2ndfloor', message: '2e verdieping' }),
    },
    states: {
        state1: defineMessage({ id: 'states.state1', message: 'schoonmaken' }),
        state2: defineMessage({ id: 'states.state2', message: 'checken' }),
        state3: defineMessage({ id: 'states.state3', message: 'klaar' }),
    },
    rooms: {
        office: defineMessage({ id: 'rooms.office', message: 'Kantoor' }),
        meeting: defineMessage({ id: 'rooms.meeting', message: 'Vergaderruimte' }),
    },
}
