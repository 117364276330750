import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import classNames from 'classnames'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import styles from './BuildingOverviewView.module.scss'
import { BackHeader } from '../../components/BackHeader/BackHeader'
import { BuildingBackground } from '../../components/BuildingBackground/BuildingBackground'
import { ButtonUp } from '../../components/ButtonUp/ButtonUp'
import { useGetFloors, useGetRooms } from '../../services/hooks'
import { FloorCard, FloorCompactCard } from '../../components/FloorCard/FloorCard'
import { RoomCard } from '../../components/RoomCard/RoomCard'
import { CleanState, RoomView } from '../../services/types'
import { PageError } from '../../../../Core/components/PageError/PageError'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../../Core/components/ActivityIndicator/ActicvityIndicator'

export const BuildingOverviewView: FunctionComponent<
    RouteComponentProps<{ id: string }, Record<string, unknown>, { back: string }>
> = props => {
    return (
        <Auth>
            <BuildingOverviewViewLayout id={props.match.params.id} back={props.location.state?.back} />
        </Auth>
    )
}

const BuildingOverviewViewLayout: FunctionComponent<{ id: string; back?: string }> = ({ id, back }) => {
    const [redner, setRender] = useState(0)

    const [floorIndex, setFloorIndex] = useState<number>(0)

    const resultFloors = useGetFloors(id)

    if (resultFloors.error) {
        throw new PageError(resultFloors.error)
    }

    const resultRooms = useGetRooms(id, resultFloors.data?.[floorIndex].id)

    if (resultRooms.error) {
        throw new PageError(resultRooms.error)
    }

    const update = (room: RoomView, state: CleanState) => {
        if (!resultFloors.data) {
            return
        }

        resultFloors.data[floorIndex].counts[room.state.value] =
            resultFloors.data[floorIndex].counts[room.state.value] - 1

        resultFloors.data[floorIndex].counts[state.value] = (resultFloors.data[floorIndex].counts[state.value] || 0) + 1

        room.state = state

        setRender(redner + 1)
    }

    const floorsContainerRef = useRef<HTMLDivElement>(null)
    const floorsCompactRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleScroll() {
            if (window.pageYOffset > window.innerHeight / 2) {
                floorsContainerRef.current?.classList.add(styles.stickyFloorsContainer)
            } else {
                floorsContainerRef.current?.classList.remove(styles.stickyFloorsContainer)
            }

            if (window.pageYOffset > window.innerWidth * 1.22) {
                floorsCompactRef.current?.classList.add(styles.stickyFloorCompact)
            } else {
                floorsCompactRef.current?.classList.remove(styles.stickyFloorCompact)
            }
        }

        window.addEventListener('scroll', handleScroll, true)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <BasicLayout
            renderDynamic={() => (
                <Fragment>
                    <div className={classNames(styles.floorsContainer)} ref={floorsContainerRef}>
                        {back && <BackHeader text={back} className={styles.backHeader} />}
                        <BuildingBackground />
                        <div className={styles.floorsContent}>
                            {resultFloors.loading ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.medium} className={styles.loading} />
                            ) : (
                                resultFloors.data?.map((f, i) => {
                                    return (
                                        <FloorCard
                                            floor={f}
                                            key={`floor_${i}`}
                                            isSelected={floorIndex === i}
                                            onClick={() => {
                                                setFloorIndex(i)
                                            }}
                                        />
                                    )
                                })
                            )}
                        </div>
                        {resultFloors.data && resultFloors.data.length > 0 && (
                            <div ref={floorsCompactRef} className={styles.floorCompact}>
                                <FloorCompactCard floor={resultFloors.data[floorIndex]} />
                            </div>
                        )}
                        <ButtonUp className={styles.buttonUp} />
                    </div>
                    <div className={classNames(styles.roomsContainer)}>
                        {resultRooms.loading ? (
                            <ActivityIndicator size={ActivityIndicatorEnumType.medium} className={styles.loading} />
                        ) : (
                            resultRooms.data?.map((r, i) => {
                                return <RoomCard room={r} key={`room_${i}`} onUpdate={state => update(r, state)} />
                            })
                        )}
                    </div>
                </Fragment>
            )}
        />
    )
}
