import { client } from '../../../Client/services/client'
import { ResponsePromise } from '../../../Client/services/request'
import { config } from '../../../config'
import { Preference, PreferenceKey } from './types'

export interface IPreferenceService {
    getPreference: <T>(userId: string, key: PreferenceKey) => ResponsePromise<Preference<T>>
}

export const preferenceService: IPreferenceService = {
    getPreference<T>(userId: string, key: PreferenceKey) {
        return client.post<Preference<T>>(config.preferences.preferencesCombined(userId), [key])
    },
}
