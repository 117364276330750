import React from 'react'
import classNames, { Argument } from 'classnames'
import styles from './BackHeader.module.scss'
import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { Icons } from '../../../Core/components/Icon/Icon'
import { useHistory } from 'react-router'

interface Props {
    className?: Argument
    text: string
}

export const BackHeader: React.FunctionComponent<Props> = ({ className, text }) => {
    const history = useHistory()

    return (
        <div className={classNames(styles.container, className)}>
            <TextButton icon={Icons.back} onClick={() => history.goBack()} style={'secondary'}>
                {text}
            </TextButton>
        </div>
    )
}
