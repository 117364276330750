import React from 'react'
import classNames, { Argument } from 'classnames'
import { Icon, Icons } from '../../../Core/components/Icon/Icon'
import { FontEnumType, InfoText, TextSizeEnumType } from '../../../Core/components/Typography/Text/Text'
import { Image } from '../../../Core/components/Image/Image'
import styles from './BuildingCard.module.scss'
import { BuildingView } from '../../services/types'
import { useHistory } from 'react-router'
import { routes } from '../../../../routes'

interface Props {
    className?: Argument
    building: BuildingView
}

export const BuildingCard: React.FunctionComponent<Props> = ({ className, building }) => {
    const history = useHistory()

    return (
        <div
            className={classNames(styles.container, className)}
            onClick={() => history.push(routes.buildingOverview(building.id), { back: building.geo.address })}
        >
            {building.imgRef && (
                <Image
                    className={styles.img}
                    load={ex =>
                        import(`../../../assets/visuals/${ex}/${building.imgRef}.${ex === 'main' ? 'png' : ex}`)
                    }
                    alt={'A buidling`s image'}
                />
            )}
            <div className={styles.geo}>
                <InfoText font={FontEnumType.secondary}>{building.geo.address}</InfoText>
                <InfoText size={TextSizeEnumType.small}>{building.geo.city}</InfoText>
            </div>
            <Icon icon={Icons.location} />
        </div>
    )
}
