import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import classNames, { Argument } from 'classnames'
import { Icons } from '../../../Core/components/Icon/Icon'
import { TextButton } from '../../../Core/components/Button/TextButton/TextButton'
import { handleOnLogout } from '../../../Auth/components/Auth/Auth'
import { UnicaLogo } from '../../../Core/components/Logo/UnicaLogo'
import styles from './MainFooter.module.scss'
import { translations } from '../../../../translations'
import { LocaleContext } from '../../../Providers/LocaleProvider'

interface Props {
    className?: Argument
}

export const MainFooter: React.FunctionComponent<Props> = ({ className }) => {
    const hystory = useHistory()

    const local = useContext(LocaleContext)

    return (
        <div className={classNames(styles.container, className)}>
            <TextButton icon={Icons.logout} onClick={() => handleOnLogout(hystory)}>
                {local._(translations.profile.logout)}
            </TextButton>
            <UnicaLogo />
        </div>
    )
}
