import { IAuth, IUser } from './types'
import { ResponsePromise } from '../../../Client/services/request'
import { client } from '../../../Client/services/client'
import { config } from '../../../config'
import { routes } from '../../../routes'
import { stringify } from 'query-string'
import { AxiosResponse } from 'axios'
import { storageService } from '../../Storage/services/service'

const onLogout = async () => {
    try {
        await authService.getLogout()
    } catch {}
    window.location.href = routes.login
}

export const withLogin = async () => {
    client.defaults.withCredentials = true

    client.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (error?.response?.status === 401 && !window.location.href.endsWith(routes.login)) {
                if (
                    error?.response?.headers['www-authenticate']?.includes('error="invalid_token"') &&
                    ['error_description="The token expired', 'error_description="The access token expired'].some(e =>
                        error?.response?.headers['www-authenticate']?.includes(e)
                    ) &&
                    authService.Auth()
                ) {
                    return authService
                        .postRefresh()
                        .then(() => {
                            return client(error.config)
                        })
                        .catch(onLogout)
                }
                onLogout()
            } else {
                return Promise.reject(error)
            }
        }
    )

    try {
        await authService.getPersistance()
    } catch {
        globalAuth = null
        globalUser = null
    }

    if (globalResolver) {
        globalResolver(globalUser || null)
        globalResolver = null
    }
}

let authInterseptor: number

const configureInterceptor = (auth: IAuth | null) => {
    client.interceptors.request.eject(authInterseptor)

    if (auth) {
        authInterseptor = client.interceptors.request.use(response => {
            if (!response.headers!.Authorization || response.headers!.Authorization !== config.oauth.apiKey) {
                response.headers!.Authorization = `${auth.token_type} ${auth.access_token}`
            }
            return response
        })
    }
}

export interface IAuthService {
    User: () => Promise<IUser | null>
    Auth: () => IAuth | null

    postLogin: (email: string, password: string, tenantId?: string) => ResponsePromise
    postToken: (token: string) => ResponsePromise
    postPayload: <T>(payload: T) => ResponsePromise

    postAD: () => Promise<void>

    postResetPassword: (email: string) => ResponsePromise
    postUpdatePassword: (token: string, password1: string, password2: string) => ResponsePromise

    getLogout: () => ResponsePromise

    postRefresh: (tenantId?: string) => ResponsePromise

    postPersistance: (auth: IAuth, user: IUser) => ResponsePromise
    getPersistance: () => ResponsePromise
    deletePersistance: () => ResponsePromise
}

let globalAuth: IAuth | null | undefined = undefined,
    globalUser: IUser | null | undefined = undefined
type GlobalResolverType = (value: IUser | null | PromiseLike<IUser>) => void
let globalPromise: Promise<IUser | null> | null = null,
    globalResolver: GlobalResolverType | null = null

export const authService: IAuthService = {
    Auth() {
        return globalAuth || null
    },

    User() {
        if (!globalPromise) {
            globalPromise = new Promise(resolve => {
                if (globalUser !== undefined) {
                    resolve(globalUser)
                } else {
                    globalResolver = resolve
                }
            })
        }

        return globalPromise
    },

    postLogin(email: string, password: string, tenantId?: string) {
        return this.postPayload({
            client_id: config.oauth.clientId,
            client_secret: config.oauth.clientSecret,
            grant_type: 'password',
            username: email,
            password,
            TenantId: tenantId,
        })
    },

    postToken(token: string) {
        return this.postPayload({
            client_id: config.oauth.clientId,
            client_secret: config.oauth.clientSecret,
            grant_type: 'delegation',
            token: token,
        })
    },

    async postPayload<T>(payload: T) {
        configureInterceptor(null)

        const data = stringify(payload),
            authResult: AxiosResponse<IAuth> = await client.post<IAuth>(config.oauth.token, data, {
                baseURL: config.oauth.basePath,
            })

        if (authResult.status === 200) {
            configureInterceptor(authResult.data)

            const userResult = await client.get<IUser>(config.oauth.user, { baseURL: config.oauth.basePath })

            if (userResult.status === 200) {
                return this.postPersistance(authResult.data, userResult.data)
            }

            return userResult
        }

        return authResult
    },

    postAD() {
        window.location.href = `${config.ad.url}&redirect_uri=${window.location.protocol}//${window.location.host}${config.ad.redirectPath}`
        return new Promise(resolve => resolve())
    },

    postResetPassword(email: string) {
        return client.post(
            config.oauth.resetPassword,
            { client_id: config.oauth.clientId, client_secret: config.oauth.clientSecret, email },
            { baseURL: config.oauth.basePath }
        )
    },

    postUpdatePassword(token: string, password1: string, password2: string) {
        return client.post(
            config.oauth.updatePassword,
            {
                client_id: config.oauth.clientId,
                client_secret: config.oauth.clientSecret,
                token,
                newPassword: password1,
                newPassword2: password2,
            },
            { baseURL: config.oauth.basePath }
        )
    },

    async getLogout() {
        configureInterceptor(null)

        const payload = stringify({
            client_id: config.oauth.clientId,
            client_secret: config.oauth.clientSecret,
            token_type_hint: 'refresh_token',
            token: globalAuth?.refresh_token || '',
        })

        await client.post(config.oauth.revocation, payload, { baseURL: config.oauth.basePath })

        storageService.clear()
        return this.deletePersistance()
    },

    async postRefresh(tenantId?: string) {
        configureInterceptor(null)

        const payload = stringify({
                client_id: config.oauth.clientId,
                client_secret: config.oauth.clientSecret,
                grant_type: 'refresh_token',
                refresh_token: globalAuth?.refresh_token || '',
                TenantId: tenantId,
            }),
            authResult = await client.post<IAuth>(config.oauth.token, payload, { baseURL: config.oauth.basePath })

        if (authResult.status === 200) {
            configureInterceptor(authResult.data)

            if (tenantId) {
                const userResult = await client.get<IUser>(config.oauth.user, { baseURL: config.oauth.basePath })

                if (userResult.status === 200) {
                    return this.postPersistance(authResult.data, userResult.data)
                }

                return userResult
            } else if (globalUser) {
                return this.postPersistance(authResult.data, globalUser)
            }
        }

        return authResult
    },

    async postPersistance(auth: IAuth, user: IUser) {
        const result = await client.post(
            config.oauth.persistence,
            { auth },
            { baseURL: config.oauth.basePath, headers: { Authorization: config.oauth.apiKey } }
        )
        globalAuth = auth
        globalUser = user
        globalPromise = null
        return result
    },

    async getPersistance() {
        const authResult = await client.get<{ auth: IAuth }>(config.oauth.persistence, {
            baseURL: config.oauth.basePath,
            headers: { Authorization: config.oauth.apiKey },
        })

        globalAuth = authResult.data?.auth || null

        if (globalAuth) {
            configureInterceptor(globalAuth)

            const userResult = await client.get<IUser>(config.oauth.user, { baseURL: config.oauth.basePath })

            globalUser = userResult.data || null

            return userResult
        }
        return authResult
    },

    async deletePersistance() {
        const result = await client.delete(config.oauth.persistence, {
            baseURL: config.oauth.basePath,
            headers: { Authorization: config.oauth.apiKey },
        })
        globalAuth = null
        globalUser = null
        globalPromise = null
        return result
    },
}
