import React from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
}

export const ButtonUp: React.FunctionComponent<Props> = props => {
    return (
        <svg className={classNames(props.className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 40">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <path
                            fill="#FFF"
                            d="M16.04 16.603C70.83 5.534 125.485 0 180 0c54.515 0 109.169 5.534 163.96 16.603C353.29 18.487 360 26.687 360 36.207V40H0v-3.793c0-9.52 6.71-17.72 16.04-19.604z"
                            transform="translate(0 -480) translate(0 480)"
                        />
                        <path
                            fill="#DADFE7"
                            fillRule="nonzero"
                            d="M193.7 24c.37 0 .679-.13.927-.39.249-.26.373-.586.373-.976 0-.225-.072-.44-.217-.647-.144-.207-.343-.358-.598-.453l-12.305-5.002c-.3-.118-.618-.236-.953-.355-.335-.118-.641-.177-.918-.177-.3 0-.616.06-.945.177-.33.119-.644.237-.944.355l-12.305 5.002c-.255.095-.454.246-.598.453-.145.207-.217.422-.217.647 0 .39.124.716.373.976.248.26.557.39.927.39.196 0 .407-.041.632-.124.226-.083.42-.16.581-.23l12.486-5.056 12.47 5.055c.174.071.376.148.607.23.231.084.44.125.624.125z"
                            transform="translate(0 -480) translate(0 480)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
