import React, { Fragment, useContext, useMemo } from 'react'
import classNames, { Argument } from 'classnames'
import styles from './FloorCard.module.scss'
import { CleanedStateValue, FloorView } from '../../services/types'
import { LocaleContext } from '../../../Providers/LocaleProvider'
import {
    FontEnumType,
    InfoText,
    TextSizeEnumType,
    TextColorEnumType,
} from '../../../Core/components/Typography/Text/Text'

interface CompactProps {
    className?: Argument
    floor: FloorView
}

interface Props extends CompactProps {
    isSelected?: boolean
    onClick?: () => void
}

export const FloorCard: React.FunctionComponent<Props> = ({ className, floor, isSelected, onClick }) => {
    const local = useContext(LocaleContext)

    const name = useMemo(() => {
        return local._(`floors.${floor.name?.toLocaleLowerCase().replace(/\s/g, '')}`)
    }, [floor.name, local])

    return (
        <div
            className={classNames(styles.container, className, {
                [styles.isSelected]: isSelected,
            })}
            onClick={onClick}
        >
            {isSelected && (
                <Fragment>
                    <InfoText>{name}</InfoText>
                    <div className={styles.counts}>
                        {[
                            floor.counts[CleanedStateValue.NeedToBeCleaned] || 0,
                            floor.counts[CleanedStateValue.NeedToBeChecked] || 0,
                            (floor.counts[CleanedStateValue.Cleaned] || 0) +
                                (floor.counts[CleanedStateValue.NotUsed] || 0),
                        ].map((count, i) => {
                            return (
                                <div className={styles.count} key={`count_${i}`}>
                                    <span
                                        className={classNames(styles.indicator, {
                                            [styles.indicator1]: i === 0,
                                            [styles.indicator2]: i === 1,
                                            [styles.indicator3]: i === 2,
                                        })}
                                    />
                                    <span className={styles.countValue}>
                                        <InfoText font={FontEnumType.secondary} size={TextSizeEnumType.large}>
                                            {count}
                                        </InfoText>
                                        <InfoText size={TextSizeEnumType.small} color={TextColorEnumType.secondary}>
                                            {local._(`states.state${i + 1}`)}
                                        </InfoText>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </Fragment>
            )}
            {!isSelected && (
                <Fragment>
                    <InfoText color={TextColorEnumType.secondary}>{name}</InfoText>
                    <InfoText color={TextColorEnumType.secondary} font={FontEnumType.secondary}>
                        {[
                            floor.counts[CleanedStateValue.NeedToBeCleaned] || 0,
                            floor.counts[CleanedStateValue.NeedToBeChecked] || 0,
                            (floor.counts[CleanedStateValue.Cleaned] || 0) +
                                (floor.counts[CleanedStateValue.NotUsed] || 0),
                        ].join(' • ')}
                    </InfoText>
                </Fragment>
            )}
        </div>
    )
}

export const FloorCompactCard: React.FunctionComponent<CompactProps> = ({ className, floor }) => {
    const local = useContext(LocaleContext)

    const name = useMemo(() => {
        return local._(`floors.${floor.name?.toLocaleLowerCase().replace(/\s/g, '')}`)
    }, [floor.name, local])

    return (
        <div className={classNames(styles.compactContainer, className)}>
            <InfoText className={styles.compaxtText}>{name}</InfoText>
            <InfoText className={styles.compaxtText} font={FontEnumType.secondary}>
                {[
                    floor.counts[CleanedStateValue.NeedToBeCleaned] || 0,
                    floor.counts[CleanedStateValue.NeedToBeChecked] || 0,
                    (floor.counts[CleanedStateValue.Cleaned] || 0) + (floor.counts[CleanedStateValue.NotUsed] || 0),
                ].join(' • ')}
            </InfoText>
        </div>
    )
}
