import React, { FunctionComponent } from 'react'
import { Auth } from '../../../Auth/components/Auth/Auth'
import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import styles from './BuildingsView.module.scss'
import { MainHeader } from '../../components/MainHeader/MainHeader'
import { useGetBuildings } from '../../services/hooks'
import { useGetLogoPreference } from '../../../Core//services/hooks'
import { BuildingCard } from '../../components/BuildingCard/BuildingCard'
import { MainFooter } from '../../components/MainFooter/MainFooter'
import { CustomerLogo } from '../../services/types'
import { useUser } from '../../../Auth/services/hooks'
import { PageError } from '../../../../Core/components/PageError/PageError'

export const BuildingsView: FunctionComponent = () => {
    return (
        <Auth>
            <BuildingsViewLayout />
        </Auth>
    )
}

const BuildingsViewLayout: FunctionComponent = () => {
    const user = useUser()
    const result = useGetBuildings()
    const resultPrefs = useGetLogoPreference(user?.TenantId, 'logo')

    const setLogo: CustomerLogo = {
        logo: resultPrefs.data?.logo,
    }

    if (result.error) {
        throw new PageError(result.error)
    }

    return (
        <BasicLayout
            loading={result.loading}
            addPaddings={true}
            renderStaticStart={() => <MainHeader logo={setLogo.logo} />}
            renderDynamic={() => (
                <div className={styles.content}>
                    {result.data?.map((b, i) => {
                        return <BuildingCard building={b} key={`building_${i}`} />
                    })}
                </div>
            )}
            renderStaticEnd={() => <MainFooter />}
        />
    )
}
