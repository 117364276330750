import styles from './Logo.module.scss'
import React from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
    logo?: string
}

export const Logo: React.FunctionComponent<Props> = props => {
    const { className, logo: test } = props
    return <span className={classNames(styles.generalLogo, props.className)}>{test}</span>
}
