import React, { FunctionComponent } from 'react'
import classNames, { Argument } from 'classnames'
import styles from './BasicLayout.module.scss'
import { Footer } from '../Footer/Footer'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
} from '../../../../../Core/components/ActivityIndicator/ActicvityIndicator'

interface Props {
    className?: Argument
    showFooter?: boolean
    showHeader?: boolean
    addPaddings?: boolean
    loading?: boolean
    renderStaticStart?: () => JSX.Element
    renderDynamic?: () => JSX.Element
    renderStaticEnd?: () => JSX.Element
}

export const BasicLayout: FunctionComponent<Props> = ({
    loading,
    showFooter = false,
    showHeader = false,
    addPaddings = false,
    className,
    renderStaticStart,
    renderDynamic,
    renderStaticEnd,
}) => {
    return (
        <div
            className={classNames(styles.container, {
                [styles.showFooter]: showFooter && !showHeader,
                [styles.showHeader]: showHeader && !showFooter,
                [styles.showBoth]: showHeader && showFooter,
                [styles.addPaddings]: addPaddings,
            })}
        >
            <div className={classNames(styles.wrapper, className)}>
                {renderStaticStart && renderStaticStart()}
                {loading ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    renderDynamic && renderDynamic()
                )}
                {renderStaticEnd && renderStaticEnd()}
            </div>
            {showFooter && <Footer />}
        </div>
    )
}
