import React from 'react'
import classNames, { Argument } from 'classnames'
import styles from './MainHeader.module.scss'
import { Logo } from '../../../Core/components/Logo/Logo'
import { Birds } from '../../../Core/components/Birds/Birds'

interface Props {
    className?: Argument
    logo?: string
}

export const MainHeader: React.FunctionComponent<Props> = ({ className, logo }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <Logo logo={logo} />
            <Birds />
        </div>
    )
}
