/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { Fragment, useContext } from 'react'
import { ErrorLayout } from '../../Core/components/Layout/ErrorLayout/ErrorLayout'
import { Button } from '../../Core/components/Button/Button'
import { LocaleContext } from '../../Providers/LocaleProvider'
import { config } from '../../../config'

export interface ErrorViewParams {
    status?: any
    logo: JSX.Element
}

export const GlobalErrorView: React.FunctionComponent<ErrorViewParams> = ({ status, logo }) => {
    const locale = useContext(LocaleContext)

    return status !== 401 ? (
        <ErrorLayout
            showFooter={true}
            renderButton={() => (
                <Button onClick={() => (window.location.href = config.defaultPath)}>
                    {locale._('errorView.backToDefault')}
                </Button>
            )}
            logo={logo}
        />
    ) : (
        <Fragment />
    )
}
