import styles from './Button.module.scss'
import React from 'react'
import classNames, { Argument } from 'classnames'
import { Icon, IconType } from '../Icon/Icon'
import { ActivityIndicator } from '../../../../Core/components/ActivityIndicator/ActicvityIndicator'

export interface ButtonProps {
    className?: Argument
    loading?: boolean
    isDisabled?: boolean
    icon?: IconType
    iconSize?: number
    onClick?: () => void
    isSubmit?: boolean
}

export class Button extends React.Component<ButtonProps> {
    public render() {
        const { children, icon, iconSize, onClick, isSubmit, loading, className } = this.props

        return (
            <button
                type={isSubmit ? 'submit' : 'button'}
                onClick={onClick}
                disabled={this.props.isDisabled}
                className={classNames(styles.wrapper, className, {
                    [styles.isDisabled]: !!this.props.isDisabled,
                })}
            >
                {loading ? (
                    <ActivityIndicator />
                ) : (
                    <>
                        {icon && <Icon icon={icon} size={iconSize} />}
                        <span>{children}</span>
                    </>
                )}
            </button>
        )
    }
}
