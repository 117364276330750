import classNames, { Argument } from 'classnames'
import React, { FunctionComponent } from 'react'
import styles from './Input.module.scss'

interface Props {
    className?: Argument
    value?: string
    placeholder?: string
    disabled?: boolean
    onChange?: (value: string) => void
    required?: boolean
    type?: string
    focus?: boolean
}

export const Input: FunctionComponent<Props> = ({
    className,
    value = '',
    placeholder,
    disabled = false,
    onChange,
    required = false,
    type,
    focus = false,
}) => {
    return (
        <input
            ref={input => input && focus && input.focus()}
            className={classNames(styles.inputWrapper, className)}
            required={required}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            onChange={e => onChange && onChange(e.target.value)}
            value={value}
        />
    )
}
