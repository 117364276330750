import React, { Fragment, FunctionComponent, Suspense, useEffect, useState } from 'react'
import classNames, { Argument } from 'classnames'
import styles from './Image.module.scss'
import { ActivityIndicator } from '../../../../Core/components/ActivityIndicator/ActicvityIndicator'

type SupportedType = 'webp' | 'main'

export interface ImageProps {
    className?: Argument
    alt: string
    //eslint-disable-next-line
    load: (ex: SupportedType) => Promise<any>
}

const SupportedTypes: SupportedType[] = ['webp']

export const Image: FunctionComponent<ImageProps> = ({ className, load, alt }) => {
    //eslint-disable-next-line
    const [images, setImages] = useState<Map<SupportedType, any>>()

    useEffect(() => {
        ;(async function loadImages() {
            const tasks = SupportedTypes.map(ex =>
                load(ex).then(image => {
                    return { ex, image: image.default }
                })
            ).concat(
                //eslint-disable-next-line
                load('main').then<{ ex: SupportedType; image: any }>(image => {
                    return { ex: 'main', image: image.default }
                })
            )

            const result = await Promise.all(tasks)

            const images = new Map()

            result.forEach(image => {
                images.set(image.ex, image.image)
            })

            setImages(images)
        })()
        //eslint-disable-next-line
    }, [])

    return (
        <Suspense fallback={<ActivityIndicator />}>
            <picture className={classNames(styles.container, className)}>
                {images && (
                    <Fragment>
                        <source type="image/webp" srcSet={images.get('webp')} />
                        <img src={images.get('main')} alt={alt} />
                    </Fragment>
                )}
            </picture>
        </Suspense>
    )
}
