import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { History } from 'history'
import { useHistory } from 'react-router-dom'
import { IUser } from '../../services/types'
import { useUser } from '../../services/hooks'
import { routes } from '../../../../routes'
import { authService } from '../../services/service'
import { config } from '../../../../config'

export const handleOnLogout = async (history: History) => {
    try {
        await authService.getLogout()
    } catch {}

    history.push(config.defaultPath)
    history.push(routes.login, { force: true })
}

export const Auth: FunctionComponent = ({ children }) => {
    const user = useUser()

    const history = useHistory()

    useEffect(() => {
        if (user === null) {
            history.push(routes.login, { force: true })
        }
    }, [user, history])

    return user ? <Fragment>{children}</Fragment> : null
}

export interface AuthParams {
    content: (user: IUser, onLogout: () => void) => JSX.Element
}

export const Auth2: FunctionComponent<AuthParams> = ({ content }) => {
    const user = useUser()

    const history = useHistory()

    useEffect(() => {
        if (user === null) {
            history.push(routes.login)
        }
    }, [user, history])

    const handleOnLogout = async () => {
        try {
            await authService.getLogout()
        } catch {}

        history.push(config.defaultPath)
        history.push(routes.login)
    }

    return user ? content(user, handleOnLogout) : null
}
