import { Preference, PreferenceKey } from './types'
import { preferenceService } from './service'
import { CustomerLogo } from '../../HierarchyView/services/types'
import { RequestStatus, useRequest } from '../../../Client/services/request'

export const useGetPreference = <T>(userId?: string, key?: PreferenceKey): RequestStatus<Preference<T> | undefined> => {
    const getProsmise = () => (userId && key ? preferenceService.getPreference<T>(userId, key) : undefined)

    return useRequest(getProsmise, [userId, key])
}

export const useGetLogoPreference = (userId?: string, key?: PreferenceKey): RequestStatus<CustomerLogo | undefined> => {
    const getProsmise = () => (userId && key ? preferenceService.getPreference(userId, key) : undefined)

    return useRequest(getProsmise, [userId, key])
}
